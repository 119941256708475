import * as React from 'react';
import { Link, graphql } from 'gatsby';

import { Layout } from '../components/Layout';
import { Seo } from '../components/Seo';
import { Breadcrumbs } from '../components/Breadcrumbs';
import { Title } from '../components/Title';
import { CardBlog } from '../components/CardBlog';
import { Form } from '../components/Form';

function BlogPostTemplate({ data: { previous, next, site, markdownRemark: post }, location }) {
    const siteTitle = site.siteMetadata?.title || 'Title';

    return (
        <Layout location={location}>
            <Breadcrumbs
                crumbs={[
                    ['/', 'Главная'],
                    ['/blog', 'Блог'],
                    [null, post.frontmatter.title],
                ]}
            />
            <div className="container section-margin">
                <Title className="h1" level="h1">
                    {post.frontmatter.title}
                </Title>
                <div className="blog-content" dangerouslySetInnerHTML={{ __html: post.html }} />
                <p>{post.frontmatter.date}</p>
            </div>
            <Form />
        </Layout>
    );
}

export function Head({ data: { markdownRemark: post } }) {
    return <Seo title={post.frontmatter.title} description={post.frontmatter.description || post.excerpt} />;
}

export default BlogPostTemplate;

export const pageQuery = graphql`
    query BlogPostBySlug($id: String!, $previousPostId: String, $nextPostId: String) {
        site {
            siteMetadata {
                title
            }
        }
        markdownRemark(id: { eq: $id }) {
            id
            excerpt(pruneLength: 160)
            html
            frontmatter {
                title
                date(formatString: "MMMM DD, YYYY")
                description
            }
        }
        previous: markdownRemark(id: { eq: $previousPostId }) {
            fields {
                slug
            }
            frontmatter {
                title
            }
        }
        next: markdownRemark(id: { eq: $nextPostId }) {
            fields {
                slug
            }
            frontmatter {
                title
            }
        }
    }
`;
